import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Calendar, Euro } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  claim?: string
  images?: ImageProps[]
  price?: string
  siteName?: string
  validity?: string
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  images,
  price,
  siteName,
  validity,
  variant = 'default',
}: Props) {
  if (!images) {
    return null
  }

  const [loaded, setLoaded] = React.useState<any>([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState([0, 0])
  const [pause, setPause] = useState(false)
  const timer = useRef<any>()

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: images.length,
    loop: true,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    move(s) {
      const opacities = s.details().positions.map((slide) => slide.portion)
      setOpacities(opacities)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    const newImageLoaded = [...loaded]
    newImageLoaded[currentSlide] = true

    setLoaded(newImageLoaded)

    if (sliderRef.current) {
      sliderRef.current.addEventListener('mouseover', () => {
        setPause(true)
      })
      sliderRef.current.addEventListener('mouseout', () => {
        setPause(false)
      })
    }

    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, pause, slider, sliderRef])

  return (
    <Container variant={variant}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <SingleImage {...images[0]} />
        </Media>
        <Media greaterThanOrEqual="ipadVertical">
          <Slider ref={sliderRef}>
            {uniq(images).map((item, index) => (
              <Slide
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                style={
                  index !== 0
                    ? {
                        opacity: opacities[index],
                      }
                    : { opacity: 1 }
                }
              >
                {loaded[index] ? <Image {...item} /> : null}
              </Slide>
            ))}
          </Slider>
          {variant === 'default' ? (
            <>
              {images.length > 1 ? (
                <Dots>
                  {images.map((item, index) => (
                    <Dot
                      key={index}
                      className={currentSlide === index ? 'active' : undefined}
                      onClick={() => {
                        slider.moveToSlideRelative(index)
                      }}
                    />
                  ))}
                </Dots>
              ) : null}
            </>
          ) : null}
        </Media>
      </MediaContextProvider>
      {siteName ? <SiteName variant={variant}>{siteName}</SiteName> : null}
      {claim ? <Claim variant={variant}>{claim}</Claim> : null}
      {price || validity ? (
        <Info row>
          {price ? (
            <Price dial={4} row>
              <Euro />
              {price}
            </Price>
          ) : null}
          {validity ? (
            <Validity dial={4} row>
              <Calendar />
              {validity}
            </Validity>
          ) : null}
        </Info>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 33%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.6)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 78vh;
        `
      case 'simple':
        return css`
          height: 78vh;

          @media (max-width: 1023px) {
            height: 50vh;
          }
        `
    }
  }}
`

const SingleImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
  &.active {
    img {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    transform: scale(1.1);
  }
`

const Dots = styled.div`
  position: absolute;
  top: 50%;
  left: 5.833vw;
  z-index: 4;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.2;
  margin-top: 1.875rem;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`

const SiteName = styled.div<ContainerProps>`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 8.125rem;
  font-weight: 600;
  letter-spacing: 0.1625rem;
  line-height: 9.375rem;
  opacity: 0.18;
  margin: auto;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 5.833vw;
  left: 5.833vw;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(-50%);
  z-index: 3;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          top: calc(50% + 3.75rem);
        `
      case 'simple':
        return css`
          display: none;
        `
    }
  }}

  @media (max-width: 1199px) {
    font-size: 4.125rem;
    letter-spacing: 0.2475rem;
    line-height: 4.125rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Claim = styled.h1<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 2.5rem;
  position: absolute;
  top: 50%;
  right: 5.833vw;
  left: 5.833vw;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(-50%);
  z-index: 3;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          top: calc(50% + 3.75rem);
        `
      case 'simple':
        return css`
          display: none;
        `
    }
  }}

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`

const Info = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  padding: 1.125rem 1.875rem;
  position: absolute;
  bottom: 0;
  left: 5.833vw;
  text-transform: uppercase;
  z-index: 3;

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 0.75rem;
  }
  > div {
    &:nth-of-type(2) {
      margin-left: 2.5rem;
    }
  }
`

const Price = styled(FlexBox)``

const Validity = styled(FlexBox)``

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'simple'
